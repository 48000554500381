<template>
  <div class="warranty-request">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu bảo hành'">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right lazy>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Xuất Excel
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template v-slot:preview>
        <!-- filter section -->
        <div>
          <b-row class="mb-5">
            <b-col>
              <BDropdownCustom
                v-model="apiParams.storeIds"
                :options="filteredStore"
                :placeholder="'cửa hàng'"
                :searchable="true"
                :matchKey="['name']"
              />
            </b-col>
            <b-col>
              <div class="d-flex">
                <b-input-group>
                  <date-picker
                    placeholder="Từ"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                </b-input-group>
                <span class="ml-1 mr-1"></span>
                <b-input-group>
                  <date-picker
                    placeholder="Đến"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </b-input-group>
              </div>
            </b-col>
            <b-col>
              <b-input
                size="sm"
                placeholder="Tên hoặc SĐT khách hàng"
                v-model="apiParams.customerSearch"
                v-on:keyup.enter="onFilter()"
                autocomplete="off"
              />
            </b-col>

            <b-col>
              <b-input
                size="sm"
                placeholder="Nhập mã IMEI"
                v-model="apiParams.imeiNo"
                v-on:keyup.enter="onFilter()"
                autocomplete="off"
              />
            </b-col>
            <b-col>
              <b-input
                size="sm"
                placeholder="Nhập tên sản phẩm"
                v-model="apiParams.productSearch"
                v-on:keyup.enter="onFilter()"
                autocomplete="off"
              />
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <b-input
                size="sm"
                placeholder="Ghi chú"
                v-model="apiParams.warrantyNote"
                v-on:keyup.enter="onFilter()"
              />
            </b-col>

            <b-col>
              <b-form-select
                size="sm"
                v-model="apiParams.status"
                :options="warrantyStatus"
                class="select-style"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>

          <b-row class="mb-5">
            <b-col cols="1">
              <b-button
                size="sm"
                variant="primary"
                style="fontweight: 600; width: 70px"
                @click="onFilter()"
                >Lọc</b-button
              >
            </b-col>
          </b-row>
        </div>
        <!-- end filter section -->

        <!-- table section -->
        <WarrantyRequestTable
          :warrantyRequests="warrantyRequests"
          :loading="onLoading"
        />
        <!-- end table section -->

        <!-- total & pagination sectiona -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="onChangePage"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- endtotal & pagination sectiona -->
      </template>
    </KTCodePreview>
    <WarrantyRequestExcel :param="apiParams" />
  </div>
</template>

<script>
import WarrantyRequestTable from '@/view/components/warranty-request/WarrantyRequestTable.vue';
import WarrantyRequestExcel from '@/view/components/warranty-request/WarrantyRequestExcel.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';

import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  makeToastFaile,
  formatDate,
  removeSelectAllOptions,
  selectAllOptions
} from '@/utils/common';
import { TIME_OUT, WARRANTY_REQUEST_STATUS } from '@/utils/constants';
import { cloneDeep, map, find, assign } from 'lodash';

import { DP_CONFIG } from '@/utils/date';
import { removeAccents } from '@/utils/common';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  components: {
    KTCodePreview,
    Loader,
    WarrantyRequestTable,
    WarrantyRequestExcel,
    BDropdownCustom
  },
  data() {
    return {
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      dpConfigs: null,
      apiParams: {
        storeName: null,
        imeiNo: null,
        customerSearch: null,
        productSearch: null,
        warrantyNote: null,
        storeIds: [],
        fromDate: null,
        toDate: null,
        status: null
      },
      warrantyRequests: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      warrantyStatus: [],
      optionStores: [],
      filteredStore: []
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    }
  },
  methods: {
    formatDate,
    onInitData() {
      this.warrantyStatus = cloneDeep(WARRANTY_REQUEST_STATUS);
    },

    onInputed(textInput = '', type) {
      switch (type) {
        case 'Store':
          this.searchStore(textInput);
          break;
        case 'Status': {
          this.searchStatus(textInput);
          break;
        }

        default:
          break;
      }
    },

    onSelected(option, type) {
      switch (type) {
         case 'Store': {
          const { items, selectItems } = selectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );
          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        default:
          break;
      }
    },

    onRemoved(option, type) {
      switch (type) {
        case 'Store': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );

          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        default:
          break;
      }
    },

    onChangePage() {
      this.fetchWarrantyRequest();
    },

    onFilter() {
      this.onRefreshPageOne();
      this.fetchWarrantyRequest();
    },

    onRefreshPageOne() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-warranty-request'
      });
    },

    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const apiParams = cloneDeep(this.apiParams);
      const statusList = cloneDeep(WARRANTY_REQUEST_STATUS);

      const idxStatus = this.apiParams.status;

      if (idxStatus) {
        apiParams.status = statusList[idxStatus].name;
      }

      apiParams.storeIds = apiParams.storeIds.map(item => item.id);
      if (apiParams.storeIds.includes(-1)) {
        apiParams.storeIds = [];
      }

      const paramsFilter = {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        ...apiParams,
        fromDate: this.apiParams.fromDate
          ? moment(apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
        toDate: this.apiParams.toDate
          ? moment(apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null
      };

      return paramsFilter;
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        // this.filteredStatus = options
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },

    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },

    fetchWarrantyRequest: function() {
      if (!this.onLoading) {
        this.onLoading = true;
        this.warrantyRequests = [];
        const params = this.getParamFilters();

        ApiService.query(
          'bill-item-warranties',
          { params },
          { timeout: TIME_OUT }
        )
          .then(response => {
            const dataset = response.data.data;
            this.totalItem = dataset.total;
            this.numberOfPage = dataset.total_page;
            this.warrantyRequests = dataset.data;
            this.onLoading = false;
          })
          .catch(error => {
            this.onLoading = false;
            this.$nprogress.done();
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi'
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          });
      }
    },

    fetchStores: async function () {
      const DEFAULT_COMPANY_ID = 3;
      ApiService.query(`stores/getStores`, {
        params: {
          companyId: DEFAULT_COMPANY_ID,
        },
      }).then((response) => {
        const stores = response.data.data;
        this.optionStores = [
          {
            id: -1,
            name: 'Tất cả cửa hàng',
            checked: false,
          },
          ...stores,
        ];
        this.filteredStore = cloneDeep(this.optionStores);
      });
    },
    downloadExcel() {
      this.$bvModal.show('modal-warranty-request-excel');
    }
  },
  created() {
    this.dpConfigs = DP_CONFIG;
    this.onInitData();
    this.onFilter();
    this.fetchStores();
    this.fetchWarrantyRequest();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu bảo hành', route: '/warranty-request' },
      { title: 'Danh sách phiếu yêu cầu bảo hành' }
    ]);
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.warranty-request {
  .checkbox-style {
    position: absolute;
    right: 0.7vw;
  }

  .custom-card-body {
    padding: 0 2.25rem;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(11% - 0.25rem);
    background-color: #f0f1f9;
  }

  .wizard.wizard-4
    .wizard-nav
    .wizard-steps
    .wizard-step[data-wizard-state='current'] {
    background-color: #ffffff;
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(17% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
}
</style>
